.container {
  padding: 1.5rem;
  display: flex;
}

.logo {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 1rem;
}

.logo > img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.logo > span {
  font-weight: 600;
}

.right {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.menu {
  display: flex;
  gap: 2rem;
  font-weight: 500;
  list-style: none;
}

.menu > li:hover {
  color: #fcfe6f;
  cursor: pointer;
}

.search {
  width: 7rem;
  height: 30%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 5px;
}

.cart {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-top: 6px;
}
